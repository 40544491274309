import React, { useState, useEffect } from "react";

function Logo() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const checkIfLoaded = () => {
      if (
        document.readyState === "complete" &&
        document.fonts.status === "loaded"
      ) {
        setLoaded(true);
      }
    };

    const interval = setInterval(() => {
      checkIfLoaded();
    }, 100);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="loader-container">
      {loaded ? "CGI" : <span>{Math.floor(Math.random() * 100)}</span>}
    </div>
  );
}

export default Logo;
