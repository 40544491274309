import React, { useState, useEffect } from 'react';
import CGIAnimation from '../Components/animation';
function About() {
    const [teammates, setTeammates] = useState([
        {
          name: 'Spencer King',
          role: 'CEO and Founder',
          image: 'https://res.cloudinary.com/dci23h4au/image/upload/v1681094294/KingCGI/Team/Spencer_pgxz3x.jpg',
          description: 'In 2022, Spencer King founded KingCGI, bringing with him a wealth of experience in the 3D industry. Beginning in 2019, he worked as a 3D artist intern for VR Connected Entertainment Inc., before moving on to become the head 3D artist at Revolutionice, a New Jersey-based company pioneering physically immersive virtual environments. During his time there, he worked with adaptive VR treadmills and full-body analysis systems. Seeking new opportunities, he traveled to Amsterdam in the summer of 2021, where he served as the art lead for WeMakeVR, contributing to the creation of immersive, multi-user VR environments. It was during this time that he recognized the immense potential of the 3D industry across a wide range of sectors, leading to the creation of KingCGI.',
          isExpanded: false,
          link: 'https://www.fiverr.com/desaikos',
        },
        {
          name: 'Michael White',
          role: '3D Character and Asset Artist',
          image: 'https://res.cloudinary.com/dci23h4au/image/upload/v1681094293/KingCGI/Team/Michael_n6xhrt.png',
          description: 'As a passionate 3D artist with a strong foundation in computer game design, Michael brings a unique blend of artistic creativity and technical expertise to his work. With a BFA in Computer Game Design from George Mason University, Michael creates visually stunning game assets and environments. As a dedicated professional, he currently works as an artist for KingCGI, where he has contributed to the development of cutting-edge environments. Constantly pushing the boundaries of his craft, leveraging expertise in 3D modeling, texturing, and rendering to bring virtual worlds to life. With a keen eye for detail and a deep understanding of game design principles, Michael strives to create immersive and visually captivating experiences for players.',
          isExpanded: false,
        },
        {
          name: 'Aimen Zaied',
          role: '3D Lighting and Asset Artist',
          image: 'https://res.cloudinary.com/dci23h4au/image/upload/v1682089130/KingCGI/Team/Aimen_j70lhb.jpg',
          description: 'Aimen is an accomplished 3D Artist boasting over six years of expertise in Blender 3D and Unity3D software. Possessing unwavering motivation and a strong sense of pride in his work, he consistently delivers top-notch 3D content with impressive turnaround times. His diverse array of services encompasses product modeling, animation, texturing, lighting, and rendering, ensuring the creation of captivating visuals that stand out from the crowd. KingCGI relies on his exceptional blend of talent, experience, and dedication to clients to complete large projects for clients across the globe.',
          isExpanded: false,
          link: 'https://www.fiverr.com/zero47',
        },
        {
          name: 'Erti Krivanjeva',
          role: 'Lead Environment Designer and Cinematographer',
          image: 'https://res.cloudinary.com/dci23h4au/image/upload/v1681094294/KingCGI/Team/Erti_jaxcts.png',
          description: "Erti is a skilled and experienced graphic designer with over five years of professional experience. They possess a strong expertise in various software such as Blender, Photoshop, After Effect, and Premiere Pro. Erti's relentless dedication to practicing and honing their craft has allowed them to develop a unique, creative style that consistently produces visually striking and effective designs. They are not afraid to tackle challenging projects and are committed to delivering exceptional work that exceeds their clients' expectations.",
          isExpanded: false,
          link: 'https://www.fiverr.com/erti19',
        },
        {
          name: 'Ihor Rubezhanskiy ',
          role: 'Lead Sculptor and Character Artist',
          image: 'https://res.cloudinary.com/dci23h4au/image/upload/v1681094300/KingCGI/Team/Igor_cqwixg.png',
          description: 'With three years of 3D sculpting experience under his belt, Ihor has successfully built a loyal client base that values his exceptional skills and talents. Capable of crafting breathtaking 3D models across a variety of genres, Ihor heads the sculpting operations at KingCGI, employing powerful tools like ZBrush to produce large-scale projects with awe-inspiring detail. As a true master of his craft, Ihor confidently tackles even the most demanding projects with remarkable proficiency.',
          isExpanded: false,
          link: 'https://www.fiverr.com/barash228',
        },
        {
          name: 'Talha Khan ',
          role: 'Lead Optimization Artist',
          image: 'https://res.cloudinary.com/dci23h4au/image/upload/v1681094377/KingCGI/Team/Talha_cufcz0.jpg',
          description: "Talha Khan is a talented 3D Asset Optimizer with over 5 years of experience in the industry. He has established himself as a reputable seller on freelancing websites, where he has successfully delivered projects to a diverse range of clients. Talha's expertise in 3D asset optimization has enabled him to develop an impressive portfolio of projects, including those related to gaming, architecture, and product design. He is known for his meticulous attention to detail and his ability to deliver high-quality results that exceed his clients' expectations.",
          isExpanded: false,
          link: 'https://www.fiverr.com/talhaumair16',
        },
        {
          name: 'Bleron Breznica ',
          role: '3D Animator and Asset Artist',
          image: 'https://res.cloudinary.com/dci23h4au/image/upload/v1683646226/KingCGI/Team/Bleroni_euhoes.png',
          description: "Bleron is a passionate 3D artist with over three years of experience. His expertise lies in professional 3D animation, modeling, and shaders, with Blender being his most utilized and trusted tool. He brings imagination to life by manipulating virtual objects and environments, creating dynamic animations that captivate audiences. Additionally, he is skilled in software such as Premiere Pro, Illustrator, and After Effects. He thrives on collaboration and adaptability, working on diverse projects ranging from architectural visualizations to character animations.",
          isExpanded: false, 
        },
        {
          name: 'Urimi Kosumi ',
          role: '3D Character and Asset Artist',
          image: 'https://res.cloudinary.com/dci23h4au/image/upload/v1683656060/KingCGI/Team/Urimi_zidmsp.jpg',
          description: "Having worked in the 3D industry for the past three years, Urimi specializes in creating stunning 3D models, animations, and textures with Blender, Premier Pro, and Photoshop. His passion is to create 3D models of unique and professional-quality work that stand out from the crowd. He has worked on projects from clients all over the world offering him a wide range of professional 3D experience.",
          isExpanded: false, 
        },
        {
          name: 'Zach Fearnow',
          role: 'Project Manager',
          image: 'https://res.cloudinary.com/dci23h4au/image/upload/v1692559777/KingCGI/Team/ZachF_tkiyot.jpg',
          description: "Zach transitioned from managing high-end restaurants to diving into the world of 3D design two years ago. Now, he excels in overseeing social media strategies, workflow processes, and project pipelines for Unreal Engine and Blender. With a background in upscale hospitality, he brings a unique touch to the digital design realm.",
          isExpanded: false, 
        },
        {
          name: 'Ramon Perez',
          role: 'Clothing Designer and Sculptor',
          image: 'https://res.cloudinary.com/dci23h4au/image/upload/v1692559777/KingCGI/Team/RamonP_njm8cw.jpg',
          description: "Over the last 3 years, Ramon has become a standout in the 3D design realm, showcasing deep expertise in Blender and Substance. His skills extend to Unreal Engine, where he's redefined digital environments. In Marvelous Designer, Ramon crafts detailed and realistic digital clothes, setting him apart as a premier choice for high-end virtual fashion and design.",
          isExpanded: false, 
        },
    ]);    
    const [showFullDescription, setShowFullDescription] = useState(false);

    function toggleDescription(index) {
        const updatedTeammates = [...teammates];
        updatedTeammates[index].isExpanded = !updatedTeammates[index].isExpanded;
        setTeammates(updatedTeammates);
    }
    
    function getDescription(description, isExpanded) {
        if (isExpanded) {
            return description;
        } else {
            return description.substring(0, 300);
        }
    }

    useEffect(() => {
        const hero_animations = document.querySelectorAll('.hero_animation') 
        const building_animation = document.querySelector('.hero_building');
        let video_number = 0;
        hero_animations.forEach(e => {
            e.addEventListener('ended', ()=>{
                video_number++
                if (video_number == hero_animations.length) {
                    for (let videos_indx = 0; videos_indx < hero_animations.length; videos_indx++) {
                        hero_animations[videos_indx].classList.add('hide');
                    }
                    building_animation.classList.remove('hide');
                    building_animation.play();
                }
            })
        });
        building_animation.addEventListener('ended', ()=>{
            video_number = 0;
            for (let videos_indx = 0; videos_indx < hero_animations.length; videos_indx++) {
                hero_animations[videos_indx].classList.remove('hide');
                hero_animations[videos_indx].play();
            } 
            building_animation.classList.add('hide'); 
        });
      }, []); 

    return (
        <div className='about_page'>
            <section className="top_section">
                <div className="top_text">
                    <h1>Embracing the Future</h1>
                    <p>
                        Our studio is at the forefront of innovation, emphasizing workflows centered around artificial intelligence and cutting-edge technology. By developing 3D pipelines tailored for seamless AI integration, we maintain a competitive edge in the industry. We are currently crafting a proprietary stable diffusion based model, named Dant-AI, designed to generate 3D images that reflect our distinct visual style. Our ambition is to incorporate Dant-AI into our immersive 3D environments and objects, pushing boundaries and exploring uncharted creative territories in the realm of 3D AI generation.
                    </p>
                </div>
                <div className="about_three_animations">
                    <CGIAnimation 
                        publicId="FemaleLowResIterate_kxgqzy" 
                        custom_class='single_media hero_animation' 
                        loopVideo={false}
                    /> 
                    <CGIAnimation 
                        publicId="MaleLowResIterate_jtfics" 
                        custom_class='single_media hero_animation' 
                        loopVideo={false}
                    /> 
                    <CGIAnimation 
                        publicId="BuildingLowResIterate_takq8t" 
                        custom_class='single_media hero_building hide' 
                        loopVideo={false}
                    />
                </div>
            </section>
            <section className='section_2'>
                <h2>Our Specializations</h2>
                <div className="section_content">
                    <p>
                        Our versatile team has completed over 320+ orders that specialize in all realms of the 3D industry. We understand all aspects of 3D creation and elevate our skills by incorporating motion capture technologies into high end projects. We are proficient in Blender, Maya, UE, Unity, Rokoko, VICON, Houdini, Photoshop, After Effects, ZBrush, and Lightroom.
                    </p>
                    <img src="https://res.cloudinary.com/dci23h4au/image/upload/v1682096147/KingCGI/Logos_ltigak.png" className="section_media" />
                </div>
            </section>
            <section className='section_3'>
                <h2>Versatility</h2>
                <div className="section_content">
                    <div className="text_icon">
                        <CGIAnimation publicId="Molecules_tmepjb_umm1ch" custom_class='icon' /> 
                        <h3>VFX Projects</h3>
                        <p>
                        Our skills span the full spectrum of 3D, with an exceptional emphasis on VFX. Our team of experts seamlessly navigates the path from concept to delivery, always armed with a contingency plan. Aware of the significant demands involved in large projects, we have diligently researched and refined optimal workflows, guaranteeing not only timely project completion but also a truly captivating visual quality
                        </p>
                    </div>
                    <div className="text_icon">
                        <img src="https://res.cloudinary.com/dci23h4au/image/upload/v1682098034/KingCGI/Soldier_hnxrua.jpg" className="icon" />
                        <h3>Game Design</h3>
                        <p>
                            Whether you require a complete art package for your game or a team of optimization specialists, KingCGI has you covered. With our profound understanding of the gaming industry, we recognize the unique behavior of 3D data in game engines as opposed to render engines. Our optimization expertise enables us to create expansive environments while maintaining peak performance, without compromising on quality
                        </p>
                    </div>
                    <div className="text_icon">
                        <CGIAnimation publicId="VRfinal_cropped_f2y61t" custom_class='icon' /> 
                        <h3>Virtual Reality Environments</h3>
                        <p>
                            As the world seeks greater immersion in virtual realms, we have made it our mission to master the intricacies of virtual reality technology and leverage it in ways that set us apart from the competition. Drawing on our experience, we identify the shortcomings of previous VR trends and focus our research on building a more immersive future. By meticulously optimizing polygon and draw call reductions, we possess the know-how to craft strikingly realistic environments that transcend top competitors while delivering an authentic experience
                        </p>
                    </div> 
                </div>
            </section>
            <section className="section_4">
                <div className="cols hide">
                    <div className="col">
                        <strong>D3 - 1</strong>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, magni?</p>
                    </div>
                    <div className="col">
                        <strong>D3 - 2</strong>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, magni?</p>
                    </div>
                    <div className="col">
                        <strong>D3 - 3</strong>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, magni?</p>
                    </div>
                    <div className="col">
                        <strong>D3 - 4</strong>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, magni?</p>
                    </div>
                </div>
                <div className="section_content hide">
                    <div className="text">
                        <h2>D4 - Lorem ipsum dolor sit amet consectetur adipisicing elit In reprehenderit.</h2>
                        <p>Here are some Recent Projects:</p>
                    </div>
                    <img src="/images/img-placeholder.gif" />
                </div>
            </section>
            <section className="section_5">
                <div className="section_text">
                    <h2>The Team</h2>
                    <p>
                        KingCGI is a global team of professionals, bringing together diverse perspectives and cultural experiences to foster a high level of collaboration and creative synergy.
                    </p>
                </div>
                <div className="team_content">
                {teammates.map((teammate, index) => (
                    <div className="teammate" key={teammate.name}>
                        <img src={teammate.image} alt={teammate.name} className='teammate_img'/>
                        {/* {teammate.link ? (
                            <a className='fiverr_link' href={teammate.link} target='_blank'>
                                <img src="https://res.cloudinary.com/dci23h4au/image/upload/w_20/v1682534895/KingCGI/1656739737fiverr-logo-black_d8sfcf.png" /> 
                                Visit On Fiverr
                            </a>
                        ) : null} */}
                        <h3 className='teamate_name'>{teammate.name}</h3>
                        <small>- {teammate.role}</small>
                        <p className='temmate_description'>
                        {getDescription(teammate.description, teammate.isExpanded)}
                            {teammate.description.length > 300 && (
                            <button onClick={() => toggleDescription(index)}>
                                {teammate.isExpanded ? 'View Less' : 'Read More'}
                            </button>
                        )}
                        </p>
                    </div>
                ))}
                </div>
            </section>
        </div>
    )
}

export default About;