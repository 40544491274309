import React from "react";
import ReactDOM from "react-dom/client";
import './assets/css/main.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


/*----------------------|
|      Import Pages     |
|----------------------*/
import Root from "./root";

import Home from "./Pages/home";
import About from "./Pages/about";


const all_pages = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about",
    element: <About />,
  },
]
const router = createBrowserRouter([ 
  {
    path: "/",
    element: <Root />,
    children: all_pages,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);