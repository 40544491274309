import React from "react"; 
import Navigation from "./Components/navigation";
import Footer from "./Components/footer";
import { Outlet } from "react-router-dom";

function root() {
    return(
        <>
            <Navigation />
            <Outlet />
            <Footer />
        </>
    )
}

export default root;