import React from 'react';
import { AdvancedVideo } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';

const CGIAnimation = ({ publicId , custom_class, loopVideo = true }) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'dci23h4au',
    },
  });
  const myVideo = cld.video(`KingCGI/${publicId}`);
  return (
    <AdvancedVideo 
        className={custom_class}
        cldVid={myVideo}
        controls={false}
        autoPlay
        muted
        loop={loopVideo}
        playsInline 
        quality="low" 
    />
  );
};

export default CGIAnimation;
