import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from "./logo";

const Navigation = () => {
  useEffect(() => {
    const isPageLoaded = () => {
      return (
        document.readyState === "complete" &&
        !!document.querySelector("body")
      );
    };
  
    const checkPageLoaded = () => {
      if (isPageLoaded()) {
        let nav_menu = document.querySelector("#nav");
        nav_menu.classList.add("nav_loaded");
        nav_menu.classList.remove("not_loaded");
      } else {
        setTimeout(checkPageLoaded, 100);
      }
    };
  
    checkPageLoaded();
  }, []);
  
  const logMenu = () => {
    const menu_icon = document.querySelector('.menu_icon');
    const nav_menu = document.querySelector('#nav');
    menu_icon.classList.toggle('fa-x')
    menu_icon.classList.toggle('fa-bars');
    nav_menu.classList.toggle('closed_menu');
  };

  return (
    <nav id="nav" className="nav not_loaded closed_menu">
      <div className="borders hide_desktop">
        <div className="right_border"></div>
        <div className="left_border"></div>
        <div className="bottom_border"></div>
      </div>
      <div className="nav_left hide">
        <div className="borders">
          <div className="top_border"></div>
          <div className="left_border"></div>
          <div className="right_border"></div>
          <div className="bottom_border"></div>
        </div>
        <ul className='nav_icons'>
          <li>
            <a className="nav-line-wrap _tb" href="#" aria-label="Dribbble">
              <i className="fa-thin fa-basketball"></i>
            </a>
          </li>
          <li>
            <a className="nav-line-wrap _tb" href="#" aria-label="Instagram">
              <i className="fa-brands fa-instagram"></i>
            </a>
          </li>
          <li>
            <a className="nav-line-wrap _tb" href="#" aria-label="Linkedin">
              <i className="fa-brands fa-linkedin"></i>
            </a>
          </li>
          <li>
            <a className="nav-line-wrap _tb" href="#" aria-label="Twitter">
              <i className="fa-brands fa-twitter"></i>
            </a>
          </li>
        </ul>
      </div>
      <div className="nav_top">
        <div className="borders">
          <div className="top_border"></div>
          <div className="left_border"></div>
          <div className="right_border"></div>
          <div className="bottom_border"></div>
        </div>
          <Link to={`/`} className='logo'>
            <Logo />
          </Link>
        <div className="links_btn">
          <ul className="links">
            <li>
              <Link to={`/`}><span>01.</span> Home </Link>
            </li>
            <li>
              <Link to={`/about`}><span>02.</span> About Us  </Link>
            </li>
            <li className='hide_desktop'>
              <a href="#"><span>03.</span> Contact </a>
            </li>
          </ul>
          <div className="right_btn">
            Contact
            <div className="arrow_icon">
              <i class="fa-solid fa-arrow-right"></i>
              <i class="fa-solid fa-arrow-right"></i>
            </div>
          </div>
          <i className="fa-solid fa-bars hide_desktop menu_icon" onClick={logMenu}></i>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
