import React, { useState, useEffect } from 'react';
import CGIAnimation from '../Components/animation';


/*----------------------|
|      Import Images    |
|----------------------*/


function Home() {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
      setIsLoaded(true);
    }, []);

    return (
        <>
            {isLoaded && (
            <>
                <section className="hero_section">
                    <div className="hero_text">
                        <h1>  Transforming your vision into tangible reality </h1>
                        <p>
                            KingCGI unites a dynamic team of seasoned VFX experts, each excelling in diverse facets of the virtual world's creative spectrum. Serving over 320+ clients in entertainment, music, and gaming, we have masterfully transformed visions into captivating experiences on any platform
                        </p>
                    </div> 
                    <CGIAnimation publicId="hero_animation_ws40vg" custom_class='hero_img' /> 
                </section>
                <section className="second_section">
                    <h2>Elevating your online brand into a breathtaking digital experience</h2>
                    <p>
                        Our team remains dedicated to staying up-to-date with the latest technologies in our field, harnessing their power to deliver top-quality 3D experiences. Collaborating with top-tier companies, we seamlessly incorporate AI, photogrammetry, and motion capture into our workflows to provide an unmatched competitive edge.
                    </p>
                </section>
                <div className="icons_section">
                    <CGIAnimation publicId="polygonREVISED_fvdfow" />
                    <CGIAnimation publicId="Diamond_nbvhti" />
                    <CGIAnimation publicId="infiniloop_wheqao" />
                </div>
                <section className="third_section">
                    <h2>Our Featured <br /> Creations</h2>
                </section>
                <section className="section_four">
                    <img src="https://res.cloudinary.com/dci23h4au/image/upload/v1682535915/KingCGI/2_ykraxj.png" alt="" className="section_img" />
                    <div className="text_side">
                        <strong>Tommy Hilfiger (PVH)</strong>
                        <h3>Creating VR Immersive Environments</h3>
                        <p>
                            Contracted through WeMakeVR, we led the visual aspects of a VR environment to showcase the new arrivals of the Tommy Hilfiger x ARIES 2022 collaboration. <a target='_blank' href="https://usa.tommy.com/en/tommy-jeans-capsules">usa.tommy.com/en/tommy-jeans-capsules</a>
                        </p> 
                    </div>
                </section>
                <section className="third_section">
                    <div className="section_content">
                        <div className="text_side">
                            <strong>Common Sense Machines</strong>
                            <h3>Consulting for the future of 3D</h3>
                            <p>We provided consulting, unlocking the full potential of cutting-edge photogrammetry technologies. Additionally, our work extended to web design services.</p>
                        </div>
                        <img src="https://res.cloudinary.com/dci23h4au/image/upload/v1683656061/KingCGI/f12_zak2if.png" alt="" className="section_img img_contain" />
                    </div>
                </section>
                <section className="section_four">
                    <img src="https://res.cloudinary.com/dci23h4au/image/upload/v1683656061/KingCGI/f4_xx9w2f.png" className="img_contain section_img" />
                    <div className="text_side">
                        <strong>WeMakeVR</strong>
                        <h3>Creating VR environments for multiplayer experiences</h3>
                        <p>
                            We led the 3D art development and optimization of multiple VR projects across multiplayer PvP, wave defense, and fashion showcasing. We also led the VR optimization for the steam ready title: Angels of Amsterdam
                        </p>
                    </div>
                </section> 
                <section className="third_section">
                    <div className="section_content">
                        <div className="text_side">
                            <strong>Delpuma</strong>
                            <h3>Creating massive VR worlds for music entertainment</h3>
                            <p>Creating large scale VR environments that allow players to view their favorite musicians live in new immersive ways. </p>
                        </div>
                        <img src="https://res.cloudinary.com/dci23h4au/image/upload/v1683656061/KingCGI/f16_xlrxe1.png" alt="" className="section_img img_contain" />
                    </div>
                </section> 
                <section className="section_five">
                    <div className="text_side">
                        <strong>Frills.de</strong>
                        <h3>Impactful visuals for signed musicians</h3>
                        <p>
                            Our team aided in the concept and execution of professional visuals for live performances.
                        </p>
                    </div> 
                    <CGIAnimation publicId='f8_qjgzck' custom_class='section_img' />
                </section>
                <section className="section_six">
                    <img src="https://res.cloudinary.com/dci23h4au/image/upload/v1682535690/KingCGI/4_gsqghz.png" alt="" className="section_img" />
                    <div className="text_side">
                        <strong>R Label Group</strong>
                        <h3>Innovating Brand Identity Across Social Media</h3>
                        <p>
                            Our team conceptualized and crafted a stunning 3D advertisement that flawlessly encapsulates the brand's unique identity and quality.
                        </p>
                    </div>
                </section>
                <section className="third_section">
                    <div className="section_content">
                        <div className="text_side">
                            <strong>Vendex</strong>
                            <h3>VFX Visuals For Live Performances</h3>
                            <p>Working with music artist Vendex, we created an immersive VFX cinematic to play for the live performance in Barcelona, Spain.</p>
                        </div>
                        <img src="https://res.cloudinary.com/dci23h4au/image/upload/v1682092986/KingCGI/1_xmpe0c.jpg" alt="" className="section_img img_contain" />
                    </div>
                </section>
                <section className="section_five">
                    <div className="text_side">
                        <strong>paperplanespr.</strong>
                        <h3>Customized VFX Cover Art</h3>
                        <p>
                            Created from scratch, we provided the 3D artwork for the EP release of artist ESHAAN from paperplanespr.
                        </p>
                    </div>
                    <img src="https://res.cloudinary.com/dci23h4au/image/upload/v1682092203/KingCGI/3_kige5l.jpg" alt="" className="section_img img_contain auto_height" />
                </section>
            </>
            )}
        </>
    )
} 
export default Home